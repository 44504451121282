'use client'

import _ from 'lodash'
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {HgThemeSelector} from '~/design-system/hg/components'
import {THEME_SWITCHER_ID} from '~/design-system/hg/components/HgTheme/HgThemeSwitcher'
import {type Theme} from '~/design-system/hg/tokens/colors'
import {HgDesktopNav} from './_components/HgDesktopNav'
import HgMobileNav from './_components/HgMobileNav'
import {type HgNavbarProps} from './types'

export const NAVBAR_CONTAINER_ID = 'mercury-navbar-container'

const NavbarThemeSelector = ({children}: {children: React.ReactNode}) => {
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null)
  const [headerTheme, setHeaderTheme] = useState<Theme | undefined>('neutral')

  const getRootMargin = () => {
    // Set root margin to be top of viewport minus half of the header height
    return typeof window !== 'undefined'
      ? `0px 0px ${
          (Math.max(
            document?.documentElement?.clientHeight || 0,
            window?.innerHeight || 0
          ) -
            Number(
              (
                window
                  .getComputedStyle(document.documentElement)
                  .getPropertyValue('--navbar-height') || '72px'
              ).split('px')[0]
            ) /
              2) *
          -1
        }px 0px`
      : '0px'
  }

  const [rootMargin, setRootMargin] = useState(getRootMargin())

  const updateRootMargin = useCallback(() => {
    setRootMargin(getRootMargin())
  }, [])

  useLayoutEffect(() => {
    // Initial root margin calculation
    updateRootMargin()
  }, [updateRootMargin])

  useEffect(() => {
    // In case of window resize, root margin needs to be recalculated
    const debouncedUpdateRootMargin = _.debounce(updateRootMargin, 100)
    debouncedUpdateRootMargin()

    window.addEventListener('resize', debouncedUpdateRootMargin)

    return () => {
      debouncedUpdateRootMargin.cancel()
      window.removeEventListener('resize', debouncedUpdateRootMargin)
    }
  }, [updateRootMargin])

  useEffect(() => {
    const observer = new IntersectionObserver(
      sections => {
        sections.forEach(section => {
          const targetSectionTheme = section.target.getAttribute(
            'data-theme'
          ) as Theme
          const isAppliedGlobally =
            section.target.getAttribute('data-apply-globally') !== 'false'

          if (section.isIntersecting && targetSectionTheme) {
            // If current section is already part of global theme, use global theme
            if (isAppliedGlobally) {
              setHeaderTheme(undefined)
            } else {
              // If current section has unique theme, set header theme to match it
              setHeaderTheme(targetSectionTheme)
            }
          }
        })
      },
      {
        rootMargin,
        threshold: 0,
      }
    )

    const pageSections = document.querySelectorAll(
      `[data-id=${THEME_SWITCHER_ID}][data-theme]`
    )
    pageSections.forEach(section => {
      observer.observe(section)
    })

    intersectionObserverRef.current = observer

    return () => {
      if (intersectionObserverRef.current) {
        intersectionObserverRef.current.disconnect()
      }
    }
  }, [rootMargin])

  return (
    <HgThemeSelector theme={headerTheme}>
      <div
        id={NAVBAR_CONTAINER_ID}
        className="sticky inset-0 z-10 -mb-navbar-height h-navbar-height"
      >
        {children}
      </div>
    </HgThemeSelector>
  )
}

const HgNavbar = ({
  navigationItems,
  primaryButtonProps,
  handleDownloadAnalyticsEvent,
  hideEscapeLinks,
}: HgNavbarProps) => {
  return (
    <NavbarThemeSelector>
      <HgDesktopNav
        className="hidden lg:flex"
        navigationItems={navigationItems}
        primaryButtonProps={primaryButtonProps}
        handleDownloadAnalyticsEvent={handleDownloadAnalyticsEvent}
        hideEscapeLinks={hideEscapeLinks}
      />
      <HgMobileNav
        className="lg:hidden"
        navigationItems={navigationItems}
        primaryButtonProps={primaryButtonProps}
        handleDownloadAnalyticsEvent={handleDownloadAnalyticsEvent}
        hideEscapeLinks={hideEscapeLinks}
      />
    </NavbarThemeSelector>
  )
}

export default HgNavbar
