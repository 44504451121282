import {type ButtonLinkComponentFragment} from '~/cms/components/ButtonLinkComponent/_generated/fragments.graphql'
import {
  type DefaultSizeButtonIconPosition,
  type HgIconType,
} from '~/design-system/hg/components'

export function getButtonLinkProps(
  data: ButtonLinkComponentFragment | undefined | null
) {
  if (!data) return undefined
  return {
    'data-analytics-event-name': data.link.analyticsEvent?.name,
    'children': data.label,
    'href': data.link.href,
    'iconProps': data.iconType
      ? {
          iconType: data.iconType.iconType as HgIconType,
          position: (data.iconPosition as DefaultSizeButtonIconPosition) ?? 'right',
        }
      : undefined,
  }
}
